import React from "react";
import CommonNavbar from "../CommonNavbar/CommonNavbar";
import Contact from "../Contact/Contact";
import CustomNavbar from "../Custom-Navbar/CustomNavbar";
import Footer from "../Footer/Footer";
import HomeBanner from "../HomeBanner/HomeBanner";
import Team from "../Ourteam/Team";
import Quality from "../Quality/Quality";
import Service from "../Service/Service";
import MetaService from "../Services/MetaService";
import Tittle from "../Tittle/Tittle";
import Why_Choose_Us from "../Why_Choose_Us/Why_Choose_Us";

const HomeManagement = () => {
  return (
    <>
      <>
        <CommonNavbar />
      </>
      <HomeBanner />
      <MetaService />
      <Service />
      <Why_Choose_Us />
      <Quality />
      <Team />
      <Tittle />
      <Contact />
      <Footer />
    </>
  );
};

export default HomeManagement;
