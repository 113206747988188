import React from "react";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import GithubIcon from "@mui/icons-material/GitHub";
const ComputerTrainerTeacher = () => {
  return (
    <div>
      <div className="ceo">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-courses-image">
                <div className="main-courses-image-2">
                  <div className="meta-text-services space-close new-text new-section">
                    <h4>
                      Instructor of
                      <span style={{ color: "#5a49f8" }}>
                        {" "}
                        Computer Training
                      </span>
                    </h4>
                    <h1 className="close-space">
                      Learn about the{" "}
                      <span
                        style={{ color: "#5a49f8" }}
                        className="new-position-for-new-text "
                      >
                        <br /> Computer Training Instructor
                      </span>{" "}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4">
              <div className="ceo-image">
                <img
                  src="https://i.ibb.co/j6WtksW/271941475-1260920057728656-3966098166261261765-n.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-xl-8">
              <div className="text-for-ceo">
                <h2>
                  About{" "}
                  <span style={{ color: "#5a49f8" }}>
                    Computer Training Instructor
                  </span>
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste
                  quidem veniam laborum hic, deleniti deserunt iure?
                </p>
                <h5>
                  {" "}
                  <ArrowRightAltRoundedIcon /> Humayon Kabir Mahid
                </h5>

                <div className="social-icon-for-ceo">
                  <p>follow him</p> <div className="specifiq-span"></div>
                  <div className="icon-customize">
                    {" "}
                    <a
                      href="https://www.facebook.com/mahidhasan.nahiyyan"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookOutlinedIcon />
                    </a>
                    <a
                      href="https://www.instagram.com/mahidhasan.nahiyyan/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramIcon />
                    </a>
                    <a
                      href="https://github.com/humayon-is-on"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <GithubIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComputerTrainerTeacher;
