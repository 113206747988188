import React from "react";
import "./Contact.scss";
import img from "../../image/contacts.png";
import emailjs from "emailjs-com";
import swal from "sweetalert";

const Contact = () => {
  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_hlawaf6",
        "template_cu2tymf",
        e.target,
        "yQ87RlZcSXQ5N-CTH"
      )
      .then(
        (result) => {
          if (result) {
            swal(
              "Thanks for your message!",
              "Keep checking back with us",
              "success"
            );
          }
        },
        (error) => {
          alert(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <>
      <div className="main-contact">
        <div className="Contact">
          <div className="container">
            <div className="meta-text-services">
              <h4>
                <span style={{ color: "#5a49f8" }}> CONTACT</span> US
              </h4>
              <h1>
                GET IN <span style={{ color: "#5a49f8" }}>TOUCH</span>{" "}
              </h1>
              <p>
                If you would like to hear from me, please email me. Please feel
                free to contact us if you have a question or <br /> just want to
                say hi. Please expect a reply from me as soon as possible!
              </p>
            </div>
            <div className="row">
              <div className="col-lg-7">
                <form onSubmit={sendEmail}>
                  <div className="single-input">
                    <input
                      type="text"
                      placeholder="Name"
                      required
                      name="name"
                    />{" "}
                  </div>
                  <br />
                  <div className="double-input">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                    />
                    <input
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <textarea
                    name="message"
                    cols="20"
                    rows="6"
                    placeholder="Please describe what you need"
                  ></textarea>
                  <br />
                  <button type="submit">Send Now</button>
                </form>
              </div>
              <div className="col-lg-5">
                <div className="address-section">
                  <img src={img} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
