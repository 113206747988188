import React from "react";
import "./GraphicsBanner.scss";

const GraphicsBanner = () => {
  return (
    <div className="GraphicsBanner">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="graphics-png">
              <img src="https://i.ibb.co/kG0X3v7/funfact-img1.png" alt="" />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="content">
              <h1>Build your carrier as a Graphics Designer</h1>
              <p>
                Are you capable of being a Web Designer & Developer. Or are you
                interested in taking a Web Designer & Developer course. If
                that's the case, then this is the right place where you can
                build your career. We can also provide you with any digital Web
                Designer & Developer service you need.
              </p>
              <br />

              <button>Let's Contact Us</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphicsBanner;
