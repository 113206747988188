import React from "react";
import Branch from "../Branch/Brance";
import CommonNavbar from "../CommonNavbar/CommonNavbar";
import ContactBanner from "../ContactBanner/ContactBanner";
import Footer from "../Footer/Footer";
import Contact from "../Contact/Contact";
import Map from "../Map/Map";

const ContactManagement = () => {
  return (
    <>
      <CommonNavbar />
      <ContactBanner />
      <Branch />
      <Contact />
      <Footer />
    </>
  );
};

export default ContactManagement;
