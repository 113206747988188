import React from "react";
import "./ComputerTrainingBanner.scss";
import img from "../../image/contacts.png";

const ComputerTrainingBanner = () => {
  return (
    <div className="ComputerTrainingBanner">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="trainer-img">
              <img src={img} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="content">
              <h1>Learn computer basic to advance </h1>
              <p>
                your one-stop-shop for computer training! Our expert instructors
                offer comprehensive training in Microsoft Office Suite, Adobe
                Creative Suite, and more, to help you succeed in today's
                tech-driven world. Whether you're a beginner or an experienced
                professional, our flexible and customizable training programs
                are designed to meet your needs and help you achieve your goals.
                Contact us today to learn more about our computer training
                services and start your journey towards success!
              </p>
              <br />

              <button>Let's Contact Us</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComputerTrainingBanner;
