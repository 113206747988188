import React from "react";
import "./DigitalMarketingDetails.scss";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";

const DigitalMarketingDetails = () => {
  const digitalList = [
    { id: 2, name: "Search Engine Optimization" },
    { id: 1, name: "Facebook Ads" },
    { id: 3, name: "Other essential techniques" },
  ];
  const digitalList2 = [
    { id: 1, name: "Comprehensive" },
    { id: 2, name: "Interactive" },
    { id: 3, name: "Engaging" },
  ];
  return (
    <div className="DigitalMarketingDetails">
      <div className="one-side-image">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="meta-text-services">
                <h4>
                  OUR{" "}
                  <span style={{ color: "#5a49f8" }}>DIGITAL MARKETING</span>{" "}
                  DETAILS
                </h4>
                <h1>
                  Here is a detailed description of our career <br />
                  <span style={{ color: "#5a49f8" }}>
                    Digital Marketing
                  </span>{" "}
                  courses
                </h1>
                <p>
                  Welcome to MetaCom Solution, your premier training center for
                  Digital Marketing and other related services! Our training
                  programs are designed to equip students with the knowledge and
                  skills necessary to succeed in the fast-paced and <br />{" "}
                  ever-evolving world of digital marketing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-5">
            <div className="instructor-image">
              <img src="https://i.ibb.co/L58JyRh/digital-intro.png" alt="*" />
            </div>
          </div>
          <div className="col-xl-7">
            <div className="content-of-digital-marketing">
              <h6>
                Our expert instructors have years of experience in the industry
                and bring a wealth of knowledge and real-world examples to our
                classes. Our Digital Marketing training program covers a wide
                range of topics including{" "}
                <span>
                  <ArrowRightAltRoundedIcon />
                </span>{" "}
                <br />
              </h6>

              <div className="list">
                <ul>
                  {digitalList.map((name) => {
                    return (
                      <li key={name?.id}>
                        <img src="https://i.ibb.co/MsFmBpt/check.png" alt="" />
                        <span>{name.name}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-xl-7">
            <div className="content-of-digital-marketing">
              <h6>
                We provide our students with hands-on experience and practical
                training, allowing them to apply what they learn in a real-world
                setting. Our training programs are designed to be{" "}
                <span>
                  <ArrowRightAltRoundedIcon />
                </span>{" "}
                <br />
              </h6>

              <div className="list">
                <ul>
                  {digitalList2.map((name) => {
                    return (
                      <li key={name?.id}>
                        <img src="https://i.ibb.co/MsFmBpt/check.png" alt="" />
                        <span>{name.name}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <h6>
                ensuring that students stay motivated and engaged throughout the
                learning process.
              </h6>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="instructor-image">
              <img src="https://i.ibb.co/kM3k531/illustration21.png" alt="*" />
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-xl-5">
            <div className="instructor-image">
              <img src="https://i.ibb.co/rQ6wCqZ/succss.gif" alt="*" />
            </div>
          </div>
          <div className="col-xl-7">
            <div className="content-of-digital-marketing">
              <h6>
                At <span style={{ color: "#5a49f8" }}>MetaCom Solution</span>,
                we understand that the world of digital marketing can be complex
                and intimidating. That’s why we provide personalized attention
                and support to each and every student, ensuring that they have
                the guidance and resources they need to succeed.
              </h6>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="content-of-digital-marketing">
              <h6>
                Whether you’re a seasoned marketing professional looking to
                expand your skillset or a newcomer to the industry, our training
                programs can help you achieve your goals and take your career to
                the next level. Contact us today to learn more about our
                training programs and how we can help you succeed in the
                exciting world of{" "}
                <span style={{ color: "#5a49f8" }}>Digital Marketing</span>!
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalMarketingDetails;
