import React from "react";
import { Link } from "react-router-dom";
import video from "../../video/pexels-alena-darmel-7729830.mp4";
import CommonNavbar from "../CommonNavbar/CommonNavbar";
import CustomNavbar from "../Custom-Navbar/CustomNavbar";

const ServiceBanners = () => {
  return (
    <>
      <div className="ServiceBanners">
        <div className="Tittle">
          <div className="video-section">
            <video src={video} muted loop autoPlay></video>
            <div className="video-overly"></div>
            <div className="text-of-title">
              <div className="quality">
                <div className="container mt-4">
                  <h1 style={{ fontWeight: "500" }}>
                    Our most popular services
                  </h1>

                  <div className="container ">
                    <div className="row">
                      <div className="col-lg-6">
                        <Link
                          to="/web-design-&-development"
                          style={{
                            textDecoration: "none",
                            color: "#FFF",
                            fontWeight: "300",
                          }}
                        >
                          {" "}
                          <h4
                            style={{
                              fontWeight: "300",
                              fontSize: "25px",
                            }}
                            className="text------"
                          >
                            Web Design & Development
                          </h4>
                          <br />
                        </Link>
                      </div>
                      <div className="col-lg-6">
                        <Link
                          to="/digital-marketing"
                          style={{
                            textDecoration: "none",
                            color: "#FFF",
                            fontWeight: "300",
                          }}
                        >
                          {" "}
                          <h4
                            style={{
                              fontWeight: "300",
                              fontSize: "25px",
                            }}
                            className="text------"
                          >
                            Digital Marketing
                          </h4>
                          <br />
                        </Link>
                      </div>
                      <div className="col-lg-6">
                        <Link
                          to="/graphics"
                          style={{
                            textDecoration: "none",
                            color: "#FFF",
                            fontWeight: "300",
                          }}
                        >
                          {" "}
                          <h4
                            style={{
                              fontWeight: "300",
                              fontSize: "25px",
                            }}
                            className="text------"
                          >
                            Graphics Design
                          </h4>
                          <br />
                        </Link>
                      </div>
                      <div className="col-lg-6">
                        <Link
                          to="/computer-training"
                          style={{
                            textDecoration: "none",
                            color: "#FFF",
                            fontWeight: "300",
                          }}
                        >
                          {" "}
                          <h4
                            style={{
                              fontWeight: "300",
                              fontSize: "25px",
                            }}
                            className="text------"
                          >
                            Computer Training
                          </h4>
                          <br />
                        </Link>
                      </div>
                    </div>
                  </div>

                  <br />
                  <Link to="/">
                    <button>Go to Home</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceBanners;
