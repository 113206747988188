import React from "react";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import "./WebDetails.scss";

const WebDetails = () => {
  const digitalList = [
    { id: 2, name: "Web Design" },
    { id: 1, name: "Web Development" },
    { id: 3, name: "FRONTEND development" },
  ];
  const digitalList2 = [
    { id: 1, name: "HTML " },
    { id: 2, name: "CSS" },
    { id: 3, name: "Bootstrap" },
    { id: 4, name: "JAVASCRIPT" },
    { id: 5, name: "React JS" },
    { id: 6, name: "Node JS" },
    { id: 7, name: "Express JS" },
  ];
  return (
    <div className="DigitalMarketingDetails">
      <div className="one-side-image">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="meta-text-services">
                <h4>
                  OUR{" "}
                  <span style={{ color: "#5a49f8" }}>
                    WEB DESIGN & DEVELOPMENT
                  </span>{" "}
                  DETAILS
                </h4>
                <h1>
                  Here is a detailed description of our career <br />
                  <span style={{ color: "#5a49f8" }}>
                    Web Design & Development
                  </span>{" "}
                  courses
                </h1>
                <p>
                  Welcome to MetaCom Solution's Web Design and Development
                  training program! Our expert instructors teach everything from
                  basic web design principles to advanced full stack website
                  development, using the latest tools and technologies. Whether
                  you're new to web development or a seasoned pro, our
                  practical, hands-on training and personalized support will
                  help you launch a successful career in this rapidly growing
                  field. Contact us today to learn more and start your journey
                  towards success!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-7">
            <div className="content-of-digital-marketing">
              <h6>
                Our comprehensive training program covers everything from basic
                web design principles to advanced full stack website
                development, and is designed to help you launch a successful
                career in the rapidly growing field of web development.{" "}
                <span>
                  <ArrowRightAltRoundedIcon />
                </span>{" "}
                <br />
              </h6>

              <div className="list">
                <ul>
                  {digitalList.map((name) => {
                    return (
                      <li key={name?.id}>
                        <img src="https://i.ibb.co/MsFmBpt/check.png" alt="" />
                        <span>{name.name}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="instructor-image">
              <img
                src="https://i.ibb.co/d42FXT4/software-development-gif.webp"
                alt="*"
              />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-xl-5">
            <div className="instructor-image new-images">
              <img src="https://i.ibb.co/rMPGd2P/web-development.gif" alt="*" />
            </div>
          </div>
          <div className="col-xl-7">
            <div className="content-of-digital-marketing">
              <h6>
                Our expert instructors bring years of experience and a wealth of
                knowledge to our classes, ensuring that our students receive the
                best possible training experience. Our Web Design and
                Development training program covers a wide range of topics
                including
                <span>
                  <ArrowRightAltRoundedIcon />
                </span>{" "}
                <br />
              </h6>

              <div className="list">
                <ul>
                  {digitalList2.map((name) => {
                    return (
                      <li key={name?.id}>
                        <img src="https://i.ibb.co/MsFmBpt/check.png" alt="" />
                        <span>{name.name}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <h6>
                We teach our students how to design and develop fully functional
                websites, and how to use the latest web development tools and
                technologies to create responsive and user-friendly websites.
              </h6>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-xl-7">
            <div className="content-of-digital-marketing">
              <h6>
                At <span style={{ color: "#5a49f8" }}>MetaCom Solution</span>,
                we understand that web development can be challenging, which is
                why we provide personalized attention and support to each and
                every student. Our instructors are available to answer
                questions, provide feedback, and offer guidance throughout the
                learning process. Our training program is designed to be
                comprehensive and practical, providing our students with
                hands-on experience and real-world projects that allow them to
                apply what they learn in a real-world setting. We also offer
                opportunities for our students to showcase their work, network
                with other professionals, and gain exposure to potential
                employers.
              </h6>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="instructor-image new-images">
              <img src="https://i.ibb.co/KwZ0pv5/web-2.gif" alt="*" />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-xl-5">
            <div className="instructor-image">
              <img src="https://i.ibb.co/hRkQMwW/web-success.gif" alt="*" />
            </div>
          </div>
          <div className="col-xl-7">
            <div className="content-of-digital-marketing">
              <h6>
                Whether you’re a seasoned web developer looking to expand your
                skills or a newcomer to the industry, our Web Design and
                Development training program can help you achieve your goals and
                take your career to the next level. Contact us today to learn
                more about our program and start your journey towards success!
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebDetails;
