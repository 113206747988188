import React from "react";
import "./HomeBanner.scss";
import Typewriter from "typewriter-effect";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import img from "../../image/contacts.png";
import { Link } from "react-router-dom";
const HomeBanner = () => {
  return (
    // <div className="home-banner-bg text-end">
    //   <div className="homeBanner">
    //     <>
    //       <div className="row">
    //         <div className="col-lg-5">
    //           <div className="primary-image">
    //             <img src={img} alt="" />
    //           </div>
    //         </div>
    //         <div className="col-lg-7 text-start">
    //           <div className="content">
    //             <h3>WELCOME TO METACOM SOLUTIONS</h3>
    //             <h1>
    //               Start your carrier with <br />
    //               <span>
    //                 {" "}
    //                 <Typewriter
    //                   options={{
    //                     strings: [
    //                       "Web Design",
    //                       "Web Development",
    //                       "Digital Marketing",
    //                       "Graphics Design",
    //                       "Facebook Ads & SEO",
    //                     ],
    //                     autoStart: true,
    //                     loop: true,
    //                   }}
    //                 />{" "}
    //               </span>
    //             </h1>
    //             <p>
    //               Metacom Solution is providing the Best IT Solutions and
    //               Training Courses. Digital Marketing, Graphics Design, Web
    //               Design & Development, Facebook Ads and SEO are our Main
    //               Courses.
    //             </p>
    //             <br />

    //             <div className="link">
    //               <button>Let's Discuss</button>
    //               <span>
    //                 Learn More <ArrowRightAltRoundedIcon />{" "}
    //               </span>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   </div>
    // </div>
    <div className="home-banner-bg text-end">
      <div className="homeBanner">
        <div className="row">
          <div className="col-lg-7">
            <div className="home-banner-text ">
              <h4>
                WELCOME TO{" "}
                <span style={{ color: "#5a49f8" }}>METACOM SOLUTIONS</span>
              </h4>
              <h1>
                Start Your Carrier With{" "}
                <Typewriter
                  options={{
                    strings: [
                      "Web Design",
                      "Web Development",
                      "Digital Marketing",
                      "Graphics Design",
                      "Facebook Ads & SEO",
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                />{" "}
              </h1>
              <p>
                Metacom Solution is providing the Best IT Solutions and Training
                Courses. <br /> Digital Marketing, Graphics Design, Web Design &
                Development, Facebook Ads and SEO are our Main Courses.
              </p>
              <div className="call-metacom">
                <a href="tel:01964071405">Call Metacom</a>
                <Link to="/about" className="about______________________">
                  <span>
                    Learn More <ArrowRightAltRoundedIcon />
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="banner-img">
              <img src="https://i.ibb.co/NnjMXgb/illustration2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
