import React from "react";
import "./ContactBanner.scss";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "../../image/pillo-vector-image.png";

const ContactBanner = () => {
  return (
    <div className="ContactBanner">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="image-for-contact-">
              <img src={YouTubeIcon} alt="" />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="contact__text">
              <div className="main__contact">
                <h4>CONTACT US</h4>
                <h1>
                  We're Ready To{" "}
                  <span style={{ color: "#2784F7" }}>Help You</span>
                </h1>
                <p>
                  If you have any questions, you can directly contact our
                  office.You can also consult the hotline number for
                  training-related information.Our Facebook and LinkedIn pages
                  are all accessible if you wish
                </p>
                <div className="social-icon-for-ceo">
                  <p className="contact-us-for-paragraph">
                    Contact us With Social Media{" "}
                  </p>{" "}
                  <div className="specifiq-span border-icon"></div>
                  <div className="icon-customize any-icons">
                    {" "}
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      <FacebookOutlinedIcon />
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      <LinkedInIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBanner;
