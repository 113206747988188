import React from "react";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";

const GraphicsDetails = () => {
  const digitalList = [
    { id: 2, name: "Typography" },
    { id: 112, name: "Color Theory" },
    { id: 3, name: "Layout Design" },
    { id: 33, name: "Image Manipulation" },
    { id: 334, name: "And more" },
  ];
  const digitalList2 = [
    { id: 1, name: "Adobe Creative Suite" },
    { id: 2, name: "To create stunning and effective designs" },
  ];
  return (
    <div className="DigitalMarketingDetails">
      <div className="one-side-image">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="meta-text-services">
                <h4>
                  OUR <span style={{ color: "#5a49f8" }}>GRAPHICS DESIGN</span>{" "}
                  DETAILS
                </h4>
                <h1>
                  Here is a detailed description of our career <br />
                  <span style={{ color: "#5a49f8" }}>Graphics Design</span>{" "}
                  courses
                </h1>
                <p>
                  Welcome to MetaCom Solution's Advanced Graphic Designing
                  training program! Our expert instructors teach the latest
                  techniques and software applications, including Adobe Creative
                  Suite, to help you create stunning and effective designs.
                  Whether you're an experienced designer or new to the industry,
                  our hands-on training and personalized support will prepare
                  you for success. Contact us today to learn more and start your
                  journey towards a successful career in graphic designing!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-7">
            <div className="content-of-digital-marketing">
              <h6>
                Our expert instructors bring years of experience and a wealth of
                knowledge to our classes, ensuring that our students receive the
                best possible training experience. Our Advanced Graphic
                Designing training program covers a wide range of topics
                including
                <span>
                  <ArrowRightAltRoundedIcon />
                </span>{" "}
                <br />
              </h6>

              <div className="list">
                <ul>
                  {digitalList.map((name) => {
                    return (
                      <li key={name?.id}>
                        <img src="https://i.ibb.co/MsFmBpt/check.png" alt="" />
                        <span>{name.name}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="instructor-image">
              <img
                src="https://i.ibb.co/bB6BC99/home-page-3-about-right.png"
                alt="*"
              />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-xl-5">
            <div className="instructor-image new-images">
              <img src="https://i.ibb.co/wh0gZzG/blog-img-3.jpg" alt="*" />
            </div>
          </div>
          <div className="col-xl-7">
            <div className="content-of-digital-marketing">
              <h6>
                We teach our students how to use the latest design tools and
                software applications including
                <span>
                  <ArrowRightAltRoundedIcon />
                </span>{" "}
                <br />
              </h6>

              <div className="list">
                <ul>
                  {digitalList2.map((name) => {
                    return (
                      <li key={name?.id}>
                        <img src="https://i.ibb.co/MsFmBpt/check.png" alt="" />
                        <span>{name.name}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-xl-7">
            <div className="content-of-digital-marketing">
              <h6>
                At <span style={{ color: "#5a49f8" }}>MetaCom Solution</span>,
                we understand that graphic designing can be challenging, which
                is why we provide personalized attention and support to each and
                every student. Our instructors are available to answer
                questions, provide feedback, and offer guidance throughout the
                learning process.Our training program is designed to be
                comprehensive and practical, providing our students with
                hands-on experience and real-world projects that allow them to
                apply what they learn in a real-world setting. We also offer
                opportunities for our students to showcase their work, network
                with other professionals, and gain exposure to potential
                employers.
              </h6>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="instructor-image new-images">
              <img src="https://i.ibb.co/ZM7mQWh/devoted-1.png" alt="*" />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-xl-5">
            <div className="instructor-image">
              <img src="https://i.ibb.co/hRkQMwW/web-success.gif" alt="*" />
            </div>
          </div>
          <div className="col-xl-7">
            <div className="content-of-digital-marketing">
              <h6>
                Whether you’re a seasoned graphic designer looking to expand
                your skills or a newcomer to the industry, our Advanced Graphic
                Designing training program can help you achieve your goals and
                take your career to the next level. Contact us today to learn
                more about our program and start your journey toward success!
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphicsDetails;
