/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import "./CustomNavbar.scss";
import logo from "../../image/logo.png";
const CustomNavbar = () => {
  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY > 40) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);
  return (
    <div
      className={
        navbar
          ? "bg-change custom-navbar display-none-for-mobile-view"
          : "custom-navbar display-none-for-mobile-view"
      }
    >
      <div className="left display-none-for-mobile-view ">
        <a href="#intro" className="logo">
          <img src={logo} alt="" />
        </a>
      </div>
      {/*  */}
      <div className="navbar custom-static-menu">
        <ul>
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/about">About</NavLink>
          </li>
          <li>
            <a href="" style={{ pointerEvents: "none", cursor: "pointer" }}>
              Services
            </a>
            <ul class="edit">
              <li>
                <NavLink to="/web-design-&-development" className="our-link">
                  Web Design & Development
                </NavLink>
              </li>
              <li>
                <NavLink to="/digital-marketing" className="our-link">
                  Digital Marketing
                </NavLink>
              </li>
              <li>
                <NavLink to="/graphics" className="our-link">
                  Graphics Design
                </NavLink>
              </li>
              <li>
                <NavLink to="/computer-training" className="our-link">
                  Computer Training
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/team">Team</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Contact</NavLink>
          </li>
        </ul>
      </div>
      {/*  */}
      <div className="phone custom-navbar-phone display-none-for-mobile-view">
        <a href="tel:01964071405">
          <AddIcCallIcon />
          Call Now
        </a>
      </div>
    </div>
  );
};

export default CustomNavbar;
