import React from "react";
import { Link } from "react-router-dom";
import "./Banner.scss";

const Banner = () => {
  return (
    <div className="Banner">
      <div className="container-xl">
        <div className="row">
          <div className="col-xl-6">
            <div className="content">
              <h1>We serve Digital Marketing solutions</h1>
              <p>
                Are you capable of being a digital marker. Or are you interested
                in taking a digital marketing course. If that's the case, then
                this is the right place where you can build your career. We can
                also provide you with any digital marketing service you need.
              </p>
              <br />

              <button>Let's Discuss</button>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="content-img">
              <img src="https://i.ibb.co/5WCfm77/12.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
