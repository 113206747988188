import React from "react";
import "./Team.scss";
import img1 from "../../image/belayet.jpg";
import img2 from "../../image/riaz32.jpg";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
const Team = () => {
  const name = [
    {
      name: "Belayet Hossain Sarder",
      about:
        "Founder and CEO of Metacom Solution. Worked as an SEO Expert and Professional Digital Marketer for a Long-Time! He has good leadership, mentorship, and entrepreneurship skills. He knows how to Train his Students to achieve success!",

      img: img1,
      id: "3223dskdfj333",
      link1: "https://www.facebook.com/smbelayet",
      link2:
        "https://www.linkedin.com/in/belayethossainlive/?originalSubdomain=bd",
    },
    {
      name: "Riaz Ahmed Akhanda",
      about:
        "Managing Director and Professional Web Developer. Passionate about his Job and Career. Worked with different Clients and Companies in and around countries.",

      img: img2,
      id: "4223dskdfj333",
      link1: "https://www.facebook.com/nisshongojiboner.pothik/",
      link2:
        "https://www.linkedin.com/in/riaz-ahmed-akhanda-752b84210/?originalSubdomain=bd",
    },
    {
      name: "SobuJ Ahmed",
      about:
        "Passionate about his Job and Career.His career and job are both very important to him. Served a wide range of clients and companies in and around different countries.",

      img: "https://i.ibb.co/GF7jfxJ/Md-Sobuj-Ahmeed-removebg-preview.png",
      id: "5223dskdfj333",
      link1:
        "https://www.facebook.com/people/Rover-Ismail-Raj-Sobuj/100010123598570/?comment_id=Y29tbWVudDoxMzA3NTY4OTQxNTA4OTdfMTMwNzcwOTQ3NDgyODI1&paipv=0&eav=AfZWrN3AIvCTA317KYEOfZj1fLshijvK408nwT9OYRSqmNQ2UYJZZxAgvwpMq4kBI1A&_rdr",
      link2: "https://www.linkedin.com/404/",
    },
    {
      name: "Nur A Alam Nahid",
      about:
        "Passionate about his Job and Career.His career and job are both very important to him. Served a wide range of clients and companies in and around different countries.",

      img: "https://i.ibb.co/dGQgD59/Nur-A-Alam-Nahid-768x992-removebg-preview.png",
      id: "5223dskdfj333",
      link1: "https://www.facebook.com/nuraalamnahiddesigner",
      link2: "https://www.linkedin.com/404/",
    },
  ];
  return (
    <div>
      <div className="team">
        <div className="container">
          <div className="team-center-img">
            <div className="meta-text-services">
              <h4>
                Know More About{" "}
                <span style={{ color: "#5a49f8" }}>Our Team</span>
              </h4>
              <h1>
                About <span style={{ color: "#5a49f8" }}>Our Team</span> <br />
              </h1>
              <p>
                Our experienced team is dedicated to providing high-quality,
                reliable IT services that are tailored to your specific needs.
                We pride ourselves on our personalized approach, and our
                commitment to delivering exceptional service that meets your{" "}
                <br /> budget and time constraints.
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid py-5">
          <div className="row">
            {name.map((names) => {
              return (
                <div className="col-lg-6" key={names.id}>
                  <div className="team-section">
                    <div className="text-of-team">
                      <img src={names.img} alt="" />
                      <h3>{names.name}</h3>
                      <p className="text-about team-text-paragraph">
                        {names.about}
                      </p>
                      <div className="social-icon-for-ceo team-social-icon">
                        <div className="icon-customize">
                          {" "}
                          <a
                            href={names.link1}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FacebookOutlinedIcon />
                          </a>
                          <a
                            href={names.link2}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <LinkedInIcon />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
