import React, { useState } from "react";
import Menu from "../Menu/Menu";
import Navbar from "../Navbar/Navbar";

const CommonNavbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
    </>
  );
};

export default CommonNavbar;
