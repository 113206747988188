import React from "react";
import "./Ceo.scss";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
const Ceo = () => {
  return (
    <div className="ceo">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="main-courses-image">
              <div className="main-courses-image-2">
                <div className="meta-text-services space-close new-text new-section">
                  <h4>
                    CEO of
                    <span style={{ color: "#5a49f8" }}> Metacom Solution</span>
                  </h4>
                  <h1 className="close-space">
                    Learn about the{" "}
                    <span
                      style={{ color: "#5a49f8" }}
                      className="new-position-for-new-text "
                    >
                      <br /> Metacom Solution CEO
                    </span>{" "}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4">
            <div className="ceo-image">
              <img src="https://i.ibb.co/cDF0XyK/belayet.jpg" alt="" />
            </div>
          </div>
          <div className="col-xl-8">
            <div className="text-for-ceo">
              <h2>
                About{" "}
                <span style={{ color: "#5a49f8" }}>Metacom Solution CEO</span>
              </h2>
              <p>
                Founder and CEO of Metacom Solution. Worked as an SEO Expert and
                Professional Digital Marketer for a Long-Time! He has good
                leadership, mentorship, and entrepreneurship skills. He knows
                how to Train his Students to achieve success!
              </p>
              <h5>
                {" "}
                <ArrowRightAltRoundedIcon /> Belayet Hossain Sarder
              </h5>

              <div className="social-icon-for-ceo">
                <p>follow him</p> <div className="specifiq-span"></div>
                <div className="icon-customize">
                  {" "}
                  <a
                    href="https://www.facebook.com/smbelayet"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookOutlinedIcon />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/belayethossainlive/?originalSubdomain=bd"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon />
                  </a>
                  <a
                    href="https://www.youtube.com/@belayethossainsarder8809"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <YouTubeIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ceo;
