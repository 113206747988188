import React from "react";

import About from "../About/About";
import Ceo from "../CEO/Ceo";
import CommonNavbar from "../CommonNavbar/CommonNavbar";
import CustomNavbar from "../Custom-Navbar/CustomNavbar";
import Footer from "../Footer/Footer";
import Goal from "../Goal/Goal";
import Team from "../Ourteam/Team";
import Why_Choose_Us from "../Why_Choose_Us/Why_Choose_Us";
import "./AboutManagement.scss";

const AboutManagement = () => {
  return (
    <div className="about-management">
      <CustomNavbar />
      <div className="mobile-menu">
        <CommonNavbar />
      </div>
      <About />
      <Goal />
      <div className="padding-need">
        <Why_Choose_Us />
      </div>
      <Ceo />
      <Team />
      <Footer />
    </div>
  );
};

export default AboutManagement;
