import React from "react";
import "./TeamBanner.scss";

const TeamBanner = () => {
  return (
    <div className="team-section-">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="team-content">
              <div className="content">
                <h1>There is an excellent team at our agency</h1>
                <p>
                  The team at our agency is always available to provide any
                  assistance that you might need.Our team is ready to provide
                  web design, graphics design, and digital marketing services.
                </p>
                <br />

                <button>Let's Contact Us</button>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="team-content-img">
              <img src="https://i.ibb.co/CHqshfF/contacts.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamBanner;
