import React from "react";
import "./Services.scss";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import img from "../../image/site-optimized-cta-image.png";
import img2 from "../../image/graphic.avif";
import { Link } from "react-router-dom";

const Service = () => {
  const services = [
    {
      name: " Digital Marketing",
      name2: "Facebook Ads | Search Engine Optimization",
      about:
        "Welcome to MetaCom Solution, your premier training center for Digital Marketing, Facebook Ads, and SEO! Our expert instructors provide comprehensive and practical training to equip you with the knowledge and skills necessary to succeed in the dynamic world of digital marketing. Whether you're a seasoned professional or a newcomer to the industry, our training programs can help you achieve your goals and take your career to the next level. Contact us today to learn more about our programs and start your journey towards success!",
      image: img,
      id: "2363737837ggdgd224",
      class: "",
      link: "digital-marketing",
    },
    {
      name: " Web Design & Development",
      name2: "Build your Career as a Web Designer & Developer ",
      about:
        "Welcome to MetaCom Solution's Web Design and Development training program! Our expert instructors teach everything from basic web design principles to advanced full stack website development, using the latest tools and technologies. Whether you're new to web development or a seasoned pro, our practical, hands-on training and personalized support will help you launch a successful career in this rapidly growing field. Contact us today to learn more and start your journey towards success",
      image: "https://i.ibb.co/wNyL5Ds/12064750-4882464.jpg",
      id: "2363737837ggdgd224j3",
      class: "",
      link: "web-design-&-development",
    },
    {
      name: " Graphic Design",
      name2: " Expert in Adobe Photoshop and Illustrator",
      about:
        "Welcome to MetaCom Solution's Advanced Graphic Designing training program! Our expert instructors teach the latest techniques and software applications, including Adobe Creative Suite, to help you create stunning and effective designs. Whether you're an experienced designer or new to the industry, our hands-on training and personalized support will prepare you for success. Contact us today to learn more and start your journey towards a successful career in graphic designing!",
      image: img2,
      id: "2363737837ggdgd22474dd",
      class: "position",
      link: "graphics",
    },
    {
      name: "Computer Training",
      name2: "Basic to Advanced",
      about:
        "Welcome to MetaCom Solution, your one-stop-shop for computer training! Our expert instructors offer comprehensive training in Microsoft Office Suite, Adobe Creative Suite, and more, to help you succeed in today's tech-driven world. Whether you're a beginner or an experienced professional, our flexible and customizable training programs are designed to meet your needs and help you achieve your goals. Contact us today to learn more about our computer training services and start your journey towards success!",
      image: "https://i.ibb.co/kBK33bw/basic-computer.jpg",
      id: "2363737837ggdgd22474ddh",
      class: "position",
      link: "computer-training",
    },
  ];
  return (
    <>
      <div className="Courses_Service">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-courses-image -customize-bg">
                <div className="main-courses-image-2">
                  <div className="meta-text-services space-close">
                    <h4>
                      OUR<span style={{ color: "#5a49f8" }}> SERVICES</span>
                    </h4>
                    <h1>
                      We provide a wide range of services{" "}
                      <span style={{ color: "#5a49f8" }}>
                        IT Solutions <br /> & Skill Development Courses
                      </span>{" "}
                    </h1>
                    <p>
                      Welcome to MetaCom Solution, your one-stop-shop for the
                      best IT services in Farm-gate, Dhaka! We understand that
                      finding the right IT services to meet your specific needs
                      can be challenging, which is why our expert team is
                      dedicated to providing customized solutions that consider
                      your budget and time constraints
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {services.map((service) => {
              return (
                <div className="col-lg-6">
                  <div className={`services-card`} key={service.id}>
                    <img src={service.image} alt="" />
                    <div className={` ${service.class}`}>
                      <h3>{service.name}</h3>
                      <h4>{service.name2}</h4>
                      <p>{service.about}</p>
                      <Link to={service.link}>
                        <button>
                          Show Details <ArrowRightAltRoundedIcon />
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
