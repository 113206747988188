import React from "react";
import CommonNavbar from "../CommonNavbar/CommonNavbar";
import ComputerTrainerTeacher from "../ComputerTrainer/ComputerTrainer";
import ComputerTrainerDetails from "../ComputerTrainerDetails/ComputerTrainerDetails";
import ComputerTrainingBanner from "../ComputerTrainingBanner/ComputerTrainingBanner";
import CustomNavbar from "../Custom-Navbar/CustomNavbar";
import Footer from "../Footer/Footer";

const ComputerTrainingManagement = () => {
  return (
    <>
      <CustomNavbar />
      <div className="mobile-menu">
        <CommonNavbar />
      </div>
      <ComputerTrainingBanner />
      <ComputerTrainerDetails />
      <ComputerTrainerTeacher />
      <Footer />
    </>
  );
};

export default ComputerTrainingManagement;
