import React from "react";

const DigitalModule = () => {
  return (
    <div className="module-container">
      <div className="Digital_module">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="meta-text-services text-left">
                <h1>
                  Here is a detailed description of our <br />
                  <span style={{ color: "#5a49f8" }}> Course Modules</span>{" "}
                </h1>
                <p>
                  Here are the details about the course modules.The subjects
                  listed here will be taught in their entirety. However, here
                  are some of the key topics that are commonly covering in our
                  digital marketing courses
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <div className="module-image">
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src="https://i.ibb.co/Zx832Hs/about94.png"
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-6">
            <div className="content-of-module">
              <h2>
                {" "}
                Here our{" "}
                <span style={{ color: "#5a49f8" }}>Digital Marketing</span>{" "}
                course module list{" "}
              </h2>
              <div className="module-accordian">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <h3 className="basic-tech"> 1 : Computer Basics</h3>
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          <strong>Module Description:</strong> This module
                          covers the knowledge, skills and attitudes required
                          for operating basic computer operation including
                          Connecting Peripherals, setting up OS Environment,
                          collecting information and Resources using Internet.
                          Preparing documents using Google Docs, Preparing
                          Spreadsheet using Google Sheet and Creating Google
                          Slides.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <h3 className="basic-tech">
                          {" "}
                          2 : Develop content for Digital Marketing
                        </h3>
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          <strong>Module Description:</strong> This module
                          covers the knowledge, skills and attitudes required
                          for developing content for Digital Marketing including
                          identifying the scope of digital marketing, developing
                          text content, developing Image Based Content and
                          developing Audio-Video Content.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <h3 className="basic-tech">
                          {" "}
                          3 : Social Media Marketing
                        </h3>
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          <strong>Module Description:</strong> This module
                          covers the knowledge, skills and attitudes required
                          for Social Media Marketing (Facebook Marketing,
                          YouTube Marketing, WhatsApp Marketing, Instagram
                          Marketing and LinkedIn Marketing etc. )
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        <h3 className="basic-tech">
                          {" "}
                          4 : Search Engine Optimization (SEO)
                        </h3>
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          <strong>Module Description:</strong> This module
                          covers the knowledge, skills and attitudes required
                          for Search Engine Optimization (SEO) including On-Page
                          SEO, Off-Page SEO and Technical SEO.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        <h3 className="basic-tech"> 5 : Mobile Marketing</h3>
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          <strong>Module Description:</strong> This module
                          covers the knowledge, skills and attitudes required
                          for Mobile Marketing including SMS Marketing and Tele
                          Marketing.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSix">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        <h3 className="basic-tech"> 6 : Email Marketing</h3>
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          <strong>Module Description:</strong> This module
                          covers the knowledge, skills and attitudes required
                          for Email Marketing including creating and setting up
                          Email Account and Email Marketing Campaign.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSeven">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        <h3 className="basic-tech">
                          {" "}
                          7 : Career Development & Freelancing
                        </h3>
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p>
                          <strong>Module Description:</strong> This module
                          covers the knowledge, skills and attitudes required
                          for Career Development including Identifying Career
                          Prospects, Creating Professionals Profile and
                          Identifying Marketplace.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalModule;
