import { useEffect, useState } from "react";
import { InfinitySpin } from "react-loader-spinner";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AboutManagement from "./components/AboutManagement/AboutManagement";
import ComputerTrainingManagement from "./components/ComputerTrainungManagement/ComputerTrainungManagement";
import ContactManagement from "./components/ContactManagement/ContactManagement";
import DigitalMarketingManagement from "./components/Digital-Markting-Management/Digital-Markting-Management";
import GraphicsManagement from "./components/GraphicsManagement/GraphicsManagement";
import HomeManagement from "./components/Home-management/HomeManagement";
import ScrollTop from "./components/ScrollToTop/ScrollToTop";
import ServiceBanners from "./components/ServiceBanners/ServiceBanners";
import TeamManagement from "./components/TeamManagement/TeamManagement";
import WebDevelopmentManagement from "./components/WebDevelopment/WebDevelopmentManagement";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);
  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        <>
          <Routes>
            <Route
              path="/"
              element={
                loading ? (
                  <div className="loader-item">
                    <InfinitySpin width="200" color="#5A49F8" />
                    <h1>
                      Welcome to <span> metacom solution </span>
                    </h1>
                  </div>
                ) : (
                  <HomeManagement />
                )
              }
            />
            <Route path="/about" element={<AboutManagement />} />
            <Route
              path="/digital-marketing"
              element={<DigitalMarketingManagement />}
            />
            <Route path="/contact" element={<ContactManagement />} />
            <Route
              path="/web-design-&-development"
              element={<WebDevelopmentManagement />}
            />
            <Route path="/team" element={<TeamManagement />} />
            <Route path="/graphics" element={<GraphicsManagement />} />
            <Route
              path="/computer-training"
              element={<ComputerTrainingManagement />}
            />
            <Route path="/our-service" element={<ServiceBanners />} />
          </Routes>
        </>
      </BrowserRouter>
    </>
  );
}

export default App;
