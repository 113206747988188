import React from "react";
import CommonNavbar from "../CommonNavbar/CommonNavbar";
import CustomNavbar from "../Custom-Navbar/CustomNavbar";
import Footer from "../Footer/Footer";
import Team from "../Ourteam/Team";
import TeamBanner from "../TeamBanner/TeamBanner";

const TeamManagement = () => {
  return (
    <>
      <CustomNavbar />
      <div className="mobile-menu">
        <CommonNavbar />
      </div>
      <TeamBanner />
      <Team />
      <Footer />
    </>
  );
};

export default TeamManagement;
