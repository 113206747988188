import React from "react";
import Typewriter from "typewriter-effect";
import "./WebBanner.scss";
const WebBanner = () => {
  return (
    <div className="web_banner">
      <div className="web-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="web-flex">
                <div className="home-banner-text any-writer">
                  <h4 className="mt-5">
                    <span style={{ color: "#5a49f8" }}>
                      Web Design / Web Development
                    </span>
                  </h4>
                  <h1>
                    Build your carrier as
                    <br />
                    <div className="Display-none">
                      <Typewriter
                        options={{
                          strings: ["A Web Designer", "A Web Developer"],
                          autoStart: true,
                          loop: true,
                        }}
                      />{" "}
                    </div>
                    <div className="content-show-for-web">
                      A Web Designer & Developer
                    </div>
                  </h1>
                  <p>
                    Are you capable of being a Web Designer & Developer. Or are
                    you interested in taking a Web Designer & Developer course.
                    If that's the case, then this is the right place where you
                    can build your career. We can also provide you with any
                    digital Web Designer & Developer service you need.
                  </p>
                  <div className="call-metacom">
                    <a href="">Let's Discuss</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="web-image">
                <img src="https://i.ibb.co/qWd5mY6/23.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebBanner;
