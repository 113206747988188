import React from "react";
import CommonNavbar from "../CommonNavbar/CommonNavbar";
import CustomNavbar from "../Custom-Navbar/CustomNavbar";
import Footer from "../Footer/Footer";
import GraphicsBanner from "../GraphicsBanner/GraphicsBanner";
import GraphicsDetails from "../GraphicsDetails/GraphicsDetails";
import GraphicsInstructor from "../GraphicsIntructor/GraphicsIntructor";

const GraphicsManagement = () => {
  return (
    <>
      <CustomNavbar />
      <div className="mobile-menu">
        <CommonNavbar />
      </div>
      <GraphicsBanner />
      <GraphicsDetails />
      <GraphicsInstructor />
      <Footer />
    </>
  );
};

export default GraphicsManagement;
