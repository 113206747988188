import React from "react";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";

const ComputerTrainerDetails = () => {
  const digitalList = [
    { id: 2, name: "Microsoft Office Suite" },
    { id: 112, name: "Adobe Creative Suite" },
    { id: 3, name: "Other essential software application" },
  ];
  const digitalList2 = [
    { id: 1, name: "Questions" },
    { id: 2, name: "Share ideas" },
    { id: 3, name: "And engage with their peers" },
  ];
  return (
    <div className="DigitalMarketingDetails">
      <div className="one-side-image">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="meta-text-services">
                <h4>
                  OUR{" "}
                  <span style={{ color: "#5a49f8" }}>COMPUTER TRAINING</span>{" "}
                  DETAILS
                </h4>
                <h1>
                  Here is a detailed description of our career <br />
                  <span style={{ color: "#5a49f8" }}>
                    Computer Training
                  </span>{" "}
                  courses
                </h1>
                <p>
                  Welcome to MetaCom Solution, your one-stop-shop for computer
                  training! Our expert instructors offer comprehensive training
                  in Microsoft Office Suite, Adobe Creative Suite, and more, to
                  help you succeed in today's tech-driven world. Whether you're
                  a beginner or an experienced professional, our flexible and
                  customizable training programs are designed to meet your needs
                  and help you achieve your goals. Contact us today to learn
                  more about our computer training services and start your
                  journey towards success!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-7">
            <div className="content-of-digital-marketing">
              <h6>
                Our comprehensive computer training programs cover a wide range
                of topics, from basic computer skills to advanced software
                applications. Our expert instructors are committed to providing
                our students with practical and hands-on training that prepares
                them for success in today’s fast-paced and technology-driven
                world. We offer a range of computer training courses, including
                <span>
                  <ArrowRightAltRoundedIcon />
                </span>{" "}
                <br />
              </h6>

              <div className="list">
                <ul>
                  {digitalList.map((name) => {
                    return (
                      <li key={name?.id}>
                        <img src="https://i.ibb.co/MsFmBpt/check.png" alt="" />
                        <span>{name.name}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="instructor-image">
              <img
                src="https://i.ibb.co/bB6BC99/home-page-3-about-right.png"
                alt="*"
              />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-xl-5">
            <div className="instructor-image new-images">
              <img src="https://i.ibb.co/wh0gZzG/blog-img-3.jpg" alt="*" />
            </div>
          </div>
          <div className="col-xl-7">
            <div className="content-of-digital-marketing">
              <h6>
                Our computer training programs are designed to be flexible and
                customizable, ensuring that our students can learn at their own
                pace and tailor their learning experience to their unique needs
                and goals. We provide a supportive and collaborative learning
                environment that encourages our students to ask
                <span>
                  <ArrowRightAltRoundedIcon />
                </span>{" "}
                <br />
              </h6>

              <div className="list">
                <ul>
                  {digitalList2.map((name) => {
                    return (
                      <li key={name?.id}>
                        <img src="https://i.ibb.co/MsFmBpt/check.png" alt="" />
                        <span>{name.name}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <h6>
                Whether you’re looking to advance your career or simply want to
                improve your computer skills, our computer training programs are
                designed to help you achieve your goals. Our programs are open
                to students of all skill levels, from complete beginners to
                experienced professionals looking to update their skills.
              </h6>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-xl-7">
            <div className="content-of-digital-marketing">
              <h6>
                At <span style={{ color: "#5a49f8" }}>MetaCom Solution</span>,
                we understand that computer training can be daunting, which is
                why we provide personalized attention and support to each and
                every student. Our instructors are available to answer
                questions, provide guidance, and offer feedback, ensuring that
                our students receive the best possible training experience.
              </h6>
            </div>
          </div>
          <div className="col-xl-5">
            <div className="instructor-image new-images">
              <img src="https://i.ibb.co/ZM7mQWh/devoted-1.png" alt="*" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComputerTrainerDetails;
