/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import "./Navbar.scss";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import { NavLink } from "react-router-dom";
import logo from "../../image/logo.png";

export default function Navbar({ menuOpen, setMenuOpen }) {
  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY > 40) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);
  return (
    <div
      className={
        navbar
          ? "change-background topbar " + (menuOpen && "active")
          : "topbar " + (menuOpen && "active")
      }
    >
      <div className="wrapper">
        <div className="left">
          <a href="#intro" className="logo">
            <img src={logo} alt="" />
          </a>
        </div>

        <div className="navbar">
          <ul>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/about">About</NavLink>
            </li>
            <li>
              <a href="" style={{ pointerEvents: "none", cursor: "pointer" }}>
                Services
              </a>
              <ul class="edit">
                <li>
                  <NavLink to="/web-design-&-development">
                    Web Design & Development
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/digital-marketing">Digital Marketing</NavLink>
                </li>
                <li>
                  <NavLink to="/graphics">Graphics Design</NavLink>
                </li>
                <li>
                  <NavLink to="/computer-training">Computer Training</NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink to="/team">Team</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
          </ul>
        </div>
        <div className="phone">
          <a href="tel:01964071405">
            <AddIcCallIcon />
            Call Now
          </a>
        </div>
        <div className="right">
          <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>
    </div>
  );
}
