import React from "react";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GithubIcon from "@mui/icons-material/GitHub";
import riaz from "../../image/riaz32.jpg";
const WebInstructor = () => {
  return (
    <>
      <div>
        <div className="ceo">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="main-courses-image">
                  <div className="main-courses-image-2">
                    <div className="meta-text-services space-close new-text new-section">
                      <h4>
                        Instructor of
                        <span style={{ color: "#5a49f8" }}>
                          {" "}
                          Web Design & Development
                        </span>
                      </h4>
                      <h1 className="close-space">
                        Learn about the{" "}
                        <span
                          style={{ color: "#5a49f8" }}
                          className="new-position-for-new-text "
                        >
                          <br /> Web Design & Development Instructor
                        </span>{" "}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4">
                <div className="ceo-image">
                  <img src={riaz} alt="" />
                </div>
              </div>
              <div className="col-xl-8">
                <div className="text-for-ceo">
                  <h2>
                    About{" "}
                    <span style={{ color: "#5a49f8" }}>
                      Web Design & Development Instructor
                    </span>
                  </h2>
                  <p>
                    Passionate about his Job and Career.His career and job are
                    both very important to him. Served a wide range of clients
                    and companies in and around different countries.
                  </p>
                  <h5>
                    {" "}
                    <ArrowRightAltRoundedIcon /> Riaz Ahmed Akhanda
                  </h5>

                  <div className="social-icon-for-ceo">
                    <p>Follow him</p> <div className="specifiq-span"></div>
                    <div className="icon-customize">
                      {" "}
                      <a
                        href="https://www.facebook.com/nisshongojiboner.pothik/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FacebookOutlinedIcon />
                      </a>
                      <a
                        href="https://www.linkedin.com/in/riaz-ahmed-akhanda-752b84210/?originalSubdomain=bd"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkedInIcon />
                      </a>
                      <a
                        href="https://github.com/riazakhanda017ksn/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <GithubIcon />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebInstructor;
