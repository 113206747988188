import React from "react";
import CommonNavbar from "../CommonNavbar/CommonNavbar";
import WebBanner from "../WebBanner/WebBanner";
import WebDetails from "../WebDetails/WebDetails";
import WebNavbar from "../WebNavbar/WebNavbar";
import WebInstructor from "../WebInstructor/WebInstructor";
import Footer from "../Footer/Footer";

const WebDevelopmentManagement = () => {
  return (
    <>
      <WebNavbar />
      <div className="mobile-menu">
        <CommonNavbar />
      </div>
      <WebBanner />
      <WebDetails />
      <WebInstructor />
      <Footer />
    </>
  );
};

export default WebDevelopmentManagement;
