import React from "react";
import "./Quality.scss";

const Quality = () => {
  return (
    <div className="quaality-container">
      <div className="quality">
        <div className="container">
          <h1>Our Service Quality</h1>
          <p>
            Our Advanced Graphic Designing training program is designed to help
            you master the latest design techniques and software applications.
            And our Web Design and Development training program teaches
            everything from basic web design principles to advanced full stack
            website development, using the latest tools and technologies.
          </p>
          <br />
          <button>Explore More </button>
        </div>
      </div>
    </div>
  );
};

export default Quality;
