import React from "react";
import "./Footer.scss";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {" "}
      <div className="footer"></div>
      <div className="main-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="need-space-bottom">
                <h4>About METACOM</h4>
                <p>
                  Metacom Solution is providing the Best IT Solutions and
                  Training Courses. Digital Marketing, Graphics Design, Web
                  Design & Development, Facebook Ads and SEO are our Main
                  Courses.
                </p>
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/MetacomSolution"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookOutlinedIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/metacomsolution/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedInIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@metacomsolution"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <YouTubeIcon />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="services need-space">
                <div className="need-space-bottom">
                  <h4>Services</h4>
                  <ul>
                    <li>
                      <Link to="/web-design-&-development">Web Design</Link>
                    </li>
                    <li>
                      <Link to="/web-design-&-development">
                        Web Development
                      </Link>
                    </li>
                    <li>
                      <Link to="/digital-marketing">Digital Marketing</Link>
                    </li>
                    <li>
                      <Link to="/graphics">Graphics Design</Link>
                    </li>
                    <li>
                      <Link to="/computer-training">Computer Training</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="need-space-bottom">
                <div className="services need-space ">
                  <h4>Company</h4>
                  <ul>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/our-service">Our Services</Link>
                    </li>
                    <li>
                      <Link to="/our-service">Our Courses</Link>
                    </li>
                    <li>
                      <Link to="/team">Our Team</Link>
                    </li>

                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="need-space-bottom">
                <div className="services need-space ">
                  <h4>Contact </h4>
                  <h5>ADDRESS</h5>
                  <p>Farmgate - Dhaka</p>
                  <h5>EMAIL</h5>
                  <p>metacomsolution@gmail.com</p>
                  <h5>Phone</h5>
                  <p>01964071405</p>
                </div>
              </div>
            </div>
          </div>
          <div className="all-right-reserved">
            <p>
              all right reserved by{" "}
              <a href="" target="_blank" rel="noopener noreferrer">
                Metacom-solution
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
