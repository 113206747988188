import React from "react";
import "./Tittle.scss";
import video from "../../video/pexels-alena-darmel-7729830.mp4";

const Tittle = () => {
  return (
    <div className="Tittle">
      <div className="video-section">
        <video src={video} muted loop autoPlay></video>
        <div className="video-overly"></div>
        <div className="text-of-title">
          <div className="quality">
            <div className="container mt-4">
              <h2>
                Experience the Future of IT Training and Services with MetaCom
                Solution in Farmgate - Dhaka
              </h2>
              <p>
                Our Advanced Graphic Designing training program is designed to
                help you master the latest design techniques and software
                applications. And our Web Design and Development training
                program teaches everything from basic web design principles to
                advanced full stack website development, using the latest tools
                and technologies.
              </p>
              <br />
              <button>Contact With Metacom Service </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tittle;
