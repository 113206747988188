import React from "react";
import Banner from "../Banner/Banner";
import CommonNavbar from "../CommonNavbar/CommonNavbar";
import CustomNavbar from "../Custom-Navbar/CustomNavbar";
import DigitalModule from "../Digital-marketing-modules/DigitalModule";
import DigitalMarkingTeacher from "../Digital-Marketing-Teacher/DigitalMarkingTeacher";
import DigitalMarketingDetails from "../DigitalMarketingDetails/DigitalMarketingDetails";
import Footer from "../Footer/Footer";

const DigitalMarketingManagement = () => {
  return (
    <div>
      <CustomNavbar />
      <div className="mobile-menu">
        <CommonNavbar />
      </div>
      <Banner />
      <DigitalMarketingDetails />
      <DigitalModule />
      <DigitalMarkingTeacher />
      <Footer />
    </div>
  );
};

export default DigitalMarketingManagement;
