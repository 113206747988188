import React from "react";
import "./Brance.scss";

const Branch = () => {
  return (
    <div className="branch">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="any-text">
              <div className="head-office">
                <h2>Our Head Office</h2>
                <h5>
                  21,2nd floor, Metacom Solution <br className="marginBottom" />
                  Capital Super Market, Green Road,Farmgate - Dhaka{" "}
                </h5>
                <br />
                <h2 style={{ color: "#2784F7" }}>Phone Number</h2>
                <h5>+8801964071405</h5>
                <h5>+8801906777757</h5>
                <br />
                <h2>Send Email</h2>
                <h5>metacomsolution@gmail.com</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-6"></div>
        </div>
      </div>
    </div>
  );
};

export default Branch;
