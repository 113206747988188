import React from "react";
import "./Goal.scss";
import { AiOutlineRight } from "react-icons/ai";

const Goal = () => {
  return (
    <div className="goals">
      <div className="container pt-5 mt-5">
        <div className="row">
          <div className="col-xl-6 ">
            <div className="goal-content mt-5">
              <h1>
                Our goal is to
                <br />
                <strong style={{ color: "#3B34C6" }}> achieve success</strong>
              </h1>
              <p>
                We have provided quality IT training to many students for one
                year and created employment opportunities for them. We are
                always ready to help people fulfill their dreams and eliminate
                unemployment.
              </p>
              <div className="portfolio">
                <span>
                  <AiOutlineRight />
                </span>
                <button>Our Portfolio</button>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="goal-img">
              <img src="https://i.ibb.co/DpTJSbn/success.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Goal;
