import React from "react";
import "./About.scss";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import img from "../../image/illustration1.png";

const About = () => {
  return (
    <div className="About">
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <div className="content">
              <h1>ABOUT METACOM</h1>
              <p>
                Metacom Solution is providing the Best IT Solutions and Training
                Courses. Digital Marketing, Graphics Design, Web Design &
                Development, Facebook Ads and SEO are our Main Courses.
              </p>
              <br />
              <div className="watch-video">
                <a href="https://youtu.be/iREth11vJIE">
                  <PlayCircleFilledIcon />
                </a>
                <span>Watch Video</span>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="about-img">
              <img src={img} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
