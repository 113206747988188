/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import "./WebNavbar.scss";
import logo from "../../image/logo.png";

const WebNavbar = () => {
  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY > 40) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);
  return (
    <div className={navbar ? "main-bg change-web-bg" : "main-bg"}>
      <div className="container">
        <div className="web-nvabr">
          <div className="left display-none-for-mobile-view ">
            <a href="#intro" className="logo">
              <img src={logo} alt="" />
            </a>
          </div>
          {/*  */}
          <div className="navbar custom-static-menu any-code">
            <ul>
              <li>
                <NavLink
                  to="/"
                  className={
                    navbar
                      ? "custom-link need-color-change"
                      : "custom-link-need"
                  }
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  className={
                    navbar
                      ? "custom-link need-color-change"
                      : "custom-link-need"
                  }
                >
                  About
                </NavLink>
              </li>

              <li>
                <a
                  href=""
                  className={
                    navbar
                      ? "custom-link need-color-change"
                      : "custom-link-need"
                  }
                  style={{ pointerEvents: "none", cursor: "pointer" }}
                >
                  Services
                </a>
                <ul class="edit">
                  <li>
                    <NavLink
                      to="/web-design-&-development"
                      className="random-ancor"
                    >
                      Web Design & Development
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/digital-marketing" className="random-ancor">
                      Digital Marketing
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/graphics" className="random-ancor">
                      Graphics Design
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/computer-design" className="random-ancor">
                      Computer Training
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to="/team">Team</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Contact</NavLink>
              </li>
            </ul>
          </div>
          {/*  */}
          <div className="phone custom-navbar-phone display-none-for-mobile-view">
            <a href="tel:01964071405">
              <AddIcCallIcon />
              Call Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebNavbar;
