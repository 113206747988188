import React from "react";
import "./Why_Choose_Us.scss";
import image from "../../image/image_processing20200121-30622-g2g2.aa886e61.gif";
const Why_Choose_Us = () => {
  return (
    <div className="new-bg main-choose-us-section">
      <div className="Why_Choose_Us">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 ">
              <div className="why-choose-us"></div>
            </div>
            <div className="col-xl-5">
              <h1>Why choose us</h1>
              <p>
                At MetaCom Solution, we offer a wide range of services to meet
                all of your IT needs. Our digital marketing services, including
                Facebook ads and search engine optimization, are designed to
                help you reach your target audience and grow your online
                presence. Our computer training program covers everything from
                basic to advanced computer skills, including Microsoft Office
                Suite and Adobe Creative Suite.
              </p>
              <br />
              <button>Contact Us</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why_Choose_Us;
