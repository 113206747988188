import React from "react";
import "./MetaService.scss";
import img1 from "../../image/services/code.png";
import img2 from "../../image/services/digital-marketing.png";
import img3 from "../../image/services/graphic-designer.png";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import { Link } from "react-router-dom";

const MetaService = () => {
  const itSolution = [
    {
      name: "Web Design & Development",
      about:
        "Providing Affordable Web Design and Development Services in Dhaka, Bangladesh. Get your Company or Personal Website Ready at Affordable Rates!",
      icon: img1,
      style: "",
      link: "web-design-&-development",
    },
    {
      name: "Digital Marketing",
      about:
        "Rank your Website on Search Ranking with our SEO Services or Boost your Sales on Facebook by Choosing our Facebook Ads Service!",
      icon: img2,
      style: "unique_class",
      link: "digital-marketing",
    },
    {
      name: "Graphics Design",
      about:
        "Make your Company Logo at Affordable Price and Make it Live using Motion Graphics with our Interns! Choose our Graphic Design Services!",
      icon: img3,
      style: "",
      link: "graphics",
    },
  ];
  return (
    <div className="MetaService">
      <div className="container">
        <div className="row">
          <div className="col-lx-12">
            <div className="meta-text-services">
              <h4>
                WHAT <span style={{ color: "#5a49f8" }}>SERVICE</span> WE
                PROVIDE?
              </h4>
              <h1>
                We are Providing the Best{" "}
                <span style={{ color: "#5a49f8" }}>
                  IT Services in Farmgate Dhaka
                </span>{" "}
                <br />
                Considering your Budget and Time!
              </h1>
              <p>
                Welcome to MetaCom Solution, your one-stop-shop for the best IT
                services in Farm-gate, Dhaka! We understand that finding the
                right IT services to meet your specific needs can be
                challenging, which is why our expert team is dedicated to
                providing customized solutions that consider your budget and
                time constraints
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {itSolution.map((itsolution) => {
            return (
              <div className="col-lg-4 mb-5">
                <div className={`service-description ${itsolution.style}}`}>
                  <img src={itsolution.icon} alt="" />
                  <h2>{itsolution.name}</h2>
                  <p>{itsolution.about}</p>
                  <Link to={itsolution.link}>
                    Readmore <ArrowRightAltRoundedIcon />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MetaService;
